import React, { useState, useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import { useApolloClient, gql } from '@apollo/client';
import { navigate } from 'gatsby';

import FiberProviderV2, { useFiberOrderContext } from './Provider';
import Spacing from 'layouts/Spacing';
import MaxWidth from 'layouts/max-width';
import Heading from 'libs/heading';
import Box from 'components/forms/Box';
import TitleAndText from 'parts/title-and-text/TitleAndText';
import AddressSearch from './AddressSearch';
import UnitSelect from './UnitSelect';
import Result from './results/Result';
import Link from 'components/Link';
import SupportingText from 'libs/SupportingText';
import bgImage from 'images/fiber/bg-decor.jpg';
import Button from 'components/forms/Button';
import { scrollToElement } from 'libs/content';
import { trackBackEnd } from 'context/AnalyticsProvider';

const DarkWrapper = styled.div`
	background-color: ${p => p.theme.colors.blue800};
	background-image: ${() => `url(${bgImage})`};
	background-size: cover;
	background-position: top;
`;

const InnerWrap = styled.div`
	padding: ${p => p.theme.spacing.desktop.small} 0;
	.text p:first-of-type {
		margin-bottom: 0;
		${p =>
			p.theme.media.large(css`
				font-size: 20px;
				line-height: 32px;
			`)}
	}
`;

/**
 * Represents a component for checking fiber delivery from the Fiber API (api-partner.nteb.no)
 * @param {Object} props - The properties for the FiberDeliveryCheck component.
 * @param {boolean} props.showResults - A boolean representing whether to show the results.
 * @returns {ReactNode} - A React element representing the FiberDeliveryCheck component.
 */
export default function FiberDeliveryCheck({ showResults = true, ...props }) {
	const [unitLoading, setUnitLoading] = useState(false);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState('');

	return (
		<FiberProviderV2 {...props}>
			{showResults ? (
				<MaxWidth id="fiber-check-address">
					<SearchBox
						unitLoading={unitLoading}
						setUnitLoading={setUnitLoading}
						loading={loading}
						setLoading={setLoading}
						setError={setError}
						{...props}
					/>

					<Result
						loading={loading}
						setLoading={setLoading}
						error={error}
					/>
				</MaxWidth>
			) : (
				<DarkWrapper id="fiber-check-address">
					<Spacing spacing={{ top: 'large', bottom: 'large' }}>
						<MaxWidth>
							<InnerWrap>
								<TitleAndText
									title="På nett. Med lysets hastighet."
									text="For å se hvilke produkter med hvilke priser vi kan tilby deg; sjekk om du kan få fiber."
									headinglevel="h2"
									bgColor="NTE Blå 800"
									nested={true}
								/>
								<SearchBox
									unitLoading={unitLoading}
									setUnitLoading={setUnitLoading}
									supportingText="cta-to-results"
									loading={loading}
									setLoading={setLoading}
									{...props}
								/>
							</InnerWrap>
						</MaxWidth>
					</Spacing>
				</DarkWrapper>
			)}
		</FiberProviderV2>
	);
}

const Fields = styled.div`
	margin: 0;
`;

const Intro = styled.p`
	font-weight: 500;
	margin-bottom: ${p => p.theme.spacing.desktop.xsmall} !important;
`;

/**
 * Represents a box with a search field and a unit select field for checking fiber delivery.
 * @param {Object} props - The properties for the SearchBox component.
 * @param {string} props.title - The title for the component.
 * @param {string} props.desc - The text for the component.
 * @param {string} props.headinglevel - The heading level for the component.
 * @param {string} props.supportingText - The supporting text for the component. (all-products | cta-to-results)
 * @param {boolean} props.unitLoading - A boolean representing the loading state of the unit.
 * @param {function} props.setUnitLoading - A function to set the loading state of the unit.
 * @param {boolean} props.loading - A boolean representing the loading state of the component.
 * @param {function} props.setLoading - A function to set the loading state of the component.
 * @param {function} props.setError - A function to set the error message.
 * @returns {ReactNode} - A React element representing the SearchBox component.
 */
function SearchBox({
	title = 'Sjekk om du kan få fiber',
	desc = 'Fyll inn adressen din og se hvilke produkter og priser vi kan tilby deg',
	headinglevel = 'h3',
	supportingText = 'all-products',
	unitLoading,
	setUnitLoading,
	loading,
	setLoading,
	setError = () => {},
	...props
}) {
	const inputRef = useRef(null);
	const hasTracked = useRef(false);

	const {
		address,
		setAddress,
		deliverResults,
		setDeliverResults,
		mainPageSlug,
		clearAddress,
		setView,
		flushFiberContext,
		flushResultContext,
		loading: contextLoading,
	} = useFiberOrderContext();
	const client = useApolloClient();

	const stateRef = useRef(address || {});

	// Set view based on URL param and scroll to results
	useEffect(() => {
		if (address?.result) {
			checkDelivery(address);
		}

		if (!props?.pageSettings?.search) return;
		const urlParams = new URLSearchParams(props?.pageSettings?.search);
		const viewParam = urlParams.get('se');

		if (viewParam !== 'alle') return;
		setView('Alle');
		if (!document?.getElementById('fiber-delivery-result')) return;
		setTimeout(() => {
			scrollToElement(
				document?.getElementById('fiber-delivery-result'),
				80
			);
		}, 150);
		//eslint-disable-next-line
	}, [props?.pagesettings?.search]);

	// Get data for the selected address
	const checkDelivery = async selected => {
		if (!selected) return;
		try {
			setLoading(true);

			// Set selected address
			inputRef.current.value = selected?.result;

			stateRef.current = { ...selected, dwelling_unit: null, units: [] };

			setAddress(stateRef.current);

			setDeliverResults({ result: null, checked: false });

			// Clear address-suggestions
			const res = await client.query({
				query: GET_ADDRESS_DATA,
				variables: {
					id: selected?.id?.toString(),
				},
				skip: !selected?.id,
			});

			const response = res?.data?.getAddressData;

			if (!response) {
				throw new Error('address not found');
			}

			stateRef.current.units = response.units || [];

			// List of reasons for delivery check
			const reasonMap = {
				'lead-not-deliverable': 'Not deliverable',
				'lead-partner': 'Deliverable by partner',
				'zero-servicepoint-matches-found':
					'Zero servicepoint matches found',
				'no-servicepoint-info': 'No servicepoint info received',
				'zero-products': 'No products received',
				'multiple-servicepoints-with-identical-dwelling':
					'Multiple servicepoints with identical dwelling unit found',
				'one-servicepoint-match': 'One servicepoint match found',
				'multiple-servicepoints':
					'Multiple servicepoints, user needs to select an unit',
			};

			if (!hasTracked.current) {
				hasTracked.current = true;
				trackBackEnd('Address Searched', {
					landing_page: response?.deliverable?.landing_page,
					query: deliverResults?.searchKeyword,
					reason: response?.code ? reasonMap[response?.code] : '',
				});
			}

			if (
				response?.result === 'unit-selection' &&
				response?.units?.length > 1
			) {
				setAddress(stateRef.current);
			} else if (['can-deliver', 'campaign'].includes(response?.result)) {
				checkDeliveryForUnit(response?.units[0]?.servicepoint_id);
				setDeliverResults({ ...response, checked: true });
			} else {
				setDeliverResults({ ...response, checked: true });
			}

			setLoading(false);

			// If not on main fiber-check page (/tv-og-internett/), navigate to it so the user can see the results
			if (props?.pageSettings?.pathname !== `/${mainPageSlug}/`) {
				navigate(`/${mainPageSlug}/`);
			}
		} catch (e) {
			console.log(e);
			setDeliverResults({ checked: true });
			setError('getAddressData error: ' + JSON.stringify(e));
			setLoading(false);
		}
	};

	// Get data for the selected unit / servicepoint
	const checkDeliveryForUnit = async id => {
		if (!id) return;
		try {
			setError('');
			setUnitLoading(true);

			const res = await client.query({
				query: GET_SERVICEPOINT_DATA,
				variables: {
					id,
				},
				skip: !id,
			});

			const response = res?.data?.getServicepointData;

			if (!response) {
				throw new Error(
					'getServicepointData error: info about unit not recieved'
				);
			}

			// Update address with selected unit
			if (response?.dwelling_unit) {
				stateRef.current.dwelling_unit = response?.dwelling_unit;

				setAddress(stateRef.current);

				setDeliverResults({ ...response, checked: true });
			} else {
				console.error(
					'getServicepointData error: dwelling_unit not found'
				);
				throw new Error(
					'getServicepointData error: dwelling_unit not found'
				);
			}
		} catch (e) {
			console.log(e);
			setError('getServicepointData error: ' + JSON.stringify(e));
		}

		setDeliverResults({ checked: true });
		setUnitLoading(false);
	};

	const reset = () => {
		setError('');
		stateRef.current = {};
		clearAddress();
		setLoading(false);
		setDeliverResults({ result: null, checked: false });

		// Flush fiber context to reset the state
		setTimeout(() => {
			flushFiberContext();
		}, 500);

		// Focus input after reset
		setTimeout(() => {
			inputRef?.current?.focus();
		}, 500);
	};

	return (
		<Box>
			{title && (
				<Heading level={headinglevel} style={{ marginBottom: '10px' }}>
					{title}
				</Heading>
			)}

			<Fields>
				{desc && <Intro>{desc}</Intro>}

				<AddressSearch
					setUnitLoading={setUnitLoading}
					loading={loading || contextLoading}
					setLoading={setLoading}
					setError={setError}
					onSelect={checkDelivery}
					onChange={value => {
						setDeliverResults({
							result: null,
							searchKeyword: value,
							checked: false,
						});
					}}
					onSearchDone={() =>
						setDeliverResults({
							checked: true,
						})
					}
					onReset={reset}
					defaultValue={!!address && !loading ? address?.result : ''}
					ref={inputRef}
					{...props}
				/>

				<UnitSelect
					value={
						address?.dwelling_unit
							? {
									label: address?.dwelling_unit,
									value: address?.dwelling_unit,
							  }
							: null
					}
					options={[
						...(address?.units?.map(unit => unit?.unit_id) || []),
						'Mitt bruksenhetsnummer står ikke på listen',
					]}
					loading={unitLoading}
					onClick={async e => {
						if (!e?.target?.textContent) return;

						flushResultContext();

						// If user clicks on "Mitt bruksenhetsnummer står ikke på listen" then we set the result to "lead"
						if (
							e?.target?.textContent ===
							'Mitt bruksenhetsnummer står ikke på listen'
						) {
							setDeliverResults({
								result: 'lead',
								code: 'users-dwelling-unit-not-in-list',
								checked: true,
							});
							return;
						}

						// Find the selected unit
						const selected = address?.units?.find(
							unit => unit?.unit_id === e?.target?.textContent
						);

						// Check delivery for the selected unit
						checkDeliveryForUnit(selected?.servicepoint_id);
					}}
					{...props}
				/>

				{supportingText === 'all-products' &&
					!(deliverResults?.result && deliverResults?.checked) && (
						<SupportingText
							style={{
								marginTop: '20px',
								marginBottom: '0 !important',
							}}>
							Her kan du se{' '}
							<Link to={`/${mainPageSlug}/alle-produkter`}>
								alle våre produkter
							</Link>
						</SupportingText>
					)}

				{supportingText === 'cta-to-results' &&
					deliverResults?.result && (
						<Button
							style={{ marginTop: '20px' }}
							to={`/${mainPageSlug}/`}>
							Se produktene du kan få
						</Button>
					)}
			</Fields>
		</Box>
	);
}

const GET_ADDRESS_DATA = gql`
	query getAddressData($id: String!) {
		getAddressData(id: $id) {
			result
			deliverable {
				level
				landing_page
				description
				activation_time
				delivery_time
				brl_base_offer
				campaigns {
					start
					end
				}
			}
			servicepoint_id
			status
			dwelling_unit
			products {
				slug
				title
				name
				api_id
				type
				variant
				mbps
				found_mbps
				included
				notIncluded
				description
				image {
					file {
						url
					}
					width
					height
					description
				}
				priority
				price12m
				priceMonth
				discount12m
				recommended
			}
			options {
				slug
				title
				name
				type
				productType
				shortDesc
				included
				image {
					file {
						url
					}
					width
					height
					description
				}
				description
				priceMonth
				establishmentFee
				establishmentFeeDiscount
			}
			project
			sharepoint_project
			code
			units {
				unit_id
				servicepoint_id
			}
			partner {
				name
				website
				show
			}
		}
	}
`;

export const GET_SERVICEPOINT_DATA = gql`
	query getServicepointData($id: String!) {
		getServicepointData(id: $id) {
			result
			servicepoint_id
			status
			dwelling_unit
			deliverable {
				level
				landing_page
				description
				activation_time
				delivery_time
				brl_base_offer
				campaigns {
					start
					end
				}
			}
			products {
				slug
				title
				name
				api_id
				type
				variant
				mbps
				found_mbps
				included
				notIncluded
				description
				image {
					file {
						url
					}
					width
					height
					description
				}
				priority
				price12m
				priceMonth
				discount12m
				recommended
			}
			options {
				slug
				title
				name
				type
				productType
				shortDesc
				included
				image {
					file {
						url
					}
					width
					height
					description
				}
				description
				priceMonth
				establishmentFee
				establishmentFeeDiscount
			}
			code
			project
			sharepoint_project
		}
	}
`;
