import React, { useMemo, useRef } from 'react';
import styled from 'styled-components';

import { useFiberOrderContext } from '../Provider';
import ProductGrid from '../ProductGrid';
import BlankButton from 'components/forms/BlankButton';
import { scrollToElement } from 'libs/content';
import Spacing from 'layouts/Spacing';
import { AnchorLink } from 'components/Link';
import { trackBackEnd } from 'context/AnalyticsProvider';

const ButtonWrap = styled.div`
	text-align: center;
	margin-top: ${p => p.theme.spacing.desktop.medium};
`;
//#endregion

/**
 * Represents a component for displaying deliverable products based on the user's address and selected view.
 * @returns {ReactNode} - A React element representing the Deliverable component.
 */
export default function Deliverable() {
	const hasTracked = useRef(false);
	const { products, productTypesResult, deliverResults, view, setView } =
		useFiberOrderContext();

	// Check if there is any active campaigns
	const activeCampaign =
		deliverResults?.deliverable?.campaigns?.length > 0
			? deliverResults?.deliverable?.campaigns[0]
			: undefined;

	/**
	 * Filter products based on the specified filter.
	 *
	 * @param {string} filter - The filter to apply ('Alle', 'Fiberbredbånd', 'Produktpakke').
	 * @returns {Array} - An array of filtered products.
	 */
	function filterProducts(filter) {
		if (!filter || filter === 'Alle') return products;
		const filtered = products?.filter(p => p?.type === filter);

		// If view is not 'Fiberbredbånd', return filtered products
		if (!view || view !== 'Fiberbredbånd') return filtered;

		// Otherwise, return filtered
		return filtered;
	}

	// Check if any recommended products are available
	const filteredProducts = filterProducts(view);
	const anyhighlighted = filteredProducts?.some(p => p?.recommended);

	useMemo(() => {
		if (!filteredProducts?.length || hasTracked.current) return;
		hasTracked.current = true;

		let option = 'Privat';
		if (deliverResults?.result === 'brl') option = 'Borettslag';
		if (deliverResults?.result === 'lead') option = 'Lead';
		if (deliverResults?.result === 'partner') option = 'Partner';

		// Track deliverable products
		trackBackEnd('Product List Viewed', {
			option,
			landing_page: deliverResults?.deliverable?.landing_page,
			products: filteredProducts?.map(p => ({
				product_id: p.api_id,
				name: p.name,
				price: p.priceMonth,
				position: p.priority,
				category: 'TV og Internett',
			})),
		});

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filteredProducts]);

	return (
		<Spacing
			id={`fiber-result-${deliverResults?.result}`}
			spacing={{ top: 'small', bottom: 'none' }}>
			<ProductGrid
				stickTitle={
					activeCampaign?.start && activeCampaign?.end
						? `Kampanje i perioden ${activeCampaign?.start} til ${activeCampaign?.end}`
						: undefined
				}
				title="Hurra! Vi kan levere til deg!"
				component={
					<p>
						{deliverResults?.deliverable?.description}
						{deliverResults?.deliverable?.landing_page ===
							'Utbygging_Villa' && (
							<>
								{' '}
								<AnchorLink
									href="/kundeservice/utbyggingsstatus"
									target="_blank">
									Her kan du se utbyggingsstatus på aktive
									prosjekter.
								</AnchorLink>
							</>
						)}
					</p>
				}
				products={
					(view !== 'Alle' && filteredProducts?.slice(0, 3)) ||
					filteredProducts
				}
				showToggle={view !== 'Alle' && productTypesResult?.length === 2}
				perrow={(view === 'Alle' && 1) || 3}
				anyhighlighted={anyhighlighted ? 'true' : 'false'}
			/>
			<ButtonWrap view={view}>
				<BlankButton
					onClick={e => {
						e.preventDefault();
						// Toggle products
						setView((view === 'Alle' && 'Produktpakke') || 'Alle');

						// Scroll to top
						scrollToElement(
							document?.getElementById('fiber-delivery-result'),
							300
						);
					}}>
					{(view === 'Alle' &&
						'Se produktene vi anbefaler for din adresse') ||
						'Se alle produktene du kan få til din adresse'}
				</BlankButton>
			</ButtonWrap>
		</Spacing>
	);
}
