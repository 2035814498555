import React from 'react';
import styled from 'styled-components';

import { useFiberOrderContext } from '../Provider';
import { useAuth } from 'context/AuthProvider';
import MediumWidth from 'layouts/medium-width';
import Spacing from 'layouts/Spacing';
import AuthButtons from 'components/authentication/AuthButtons';
import AuthInformation from 'components/authentication/AuthInformation';
import TitleAndText from 'parts/title-and-text/TitleAndText';
import Deliverable from './Deliverable';
import Loading from 'components/Loading';

const MediumWidthWrap = styled(MediumWidth)`
	padding: 0;
`;

export default function Campaign() {
	const { deliverResults, mainPageSlug } = useFiberOrderContext();
	const { isAuthenticated, loading } = useAuth();

	if (loading) return <Loading />;

	// If the user is authenticated, show the Deliverable component
	if (isAuthenticated) {
		return <Deliverable />;
	}

	// Check if there is any active campaigns
	const activeCampaign =
		deliverResults?.deliverable?.campaigns?.length > 0
			? deliverResults?.deliverable?.campaigns[0]
			: undefined;

	// If the user is not authenticated, show the authenticate components
	return (
		<MediumWidthWrap id="fiber-by-campaign">
			<Spacing spacing={{ top: 'small', bottom: 'none' }}>
				<TitleAndText
					stickTitle={
						activeCampaign?.start && activeCampaign?.end
							? `Vi har kampanje i ditt område fra ${activeCampaign?.start} til ${activeCampaign?.end}`
							: undefined
					}
					title="Logg inn for å se hvilket kampanjetilbud du kan få."
					text="For å velge produktpakke, må du identifisere deg. Dette gir deg også tilgang til Mine Sider og andre tjenester."
					nested={true}
				/>

				<AuthButtons
					title={null}
					text={null}
					redirect={`/${mainPageSlug}/`}
					headinglevel="h2"
				/>
				<AuthInformation />
			</Spacing>
		</MediumWidthWrap>
	);
}
